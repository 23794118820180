import "react-image-lightbox/style.css"
import "./styles.scss"

import React, { useRef, useState } from "react"
import Slider from "react-slick"
import scrollToElement from "scroll-to-element"
import Lightbox from "react-image-lightbox"
import { AnimatePresence, motion } from "framer-motion"

import Button from "components/Button"

import IcoArrow from "assets/icons/ico-button-arrow-back.svg"

const Main = ({ data, viewState }) => {
  const [view, setView] = useState("2d")
  const [subview, setSubview] = useState("zero")
  const [lightbox, setLightbox] = useState(null)
  const [isPopOpen, setIsPopOpen] = useState(true)

  const popSliderRef = useRef(null)

  const pdf_name = `mazowiecka115-${data.slug}.pdf`
  const vis2d = data?.acfApartment?.visualization2d?.sourceUrl
  const vis2d_zero =
    data?.acfApartment?.visualization2dZero?.localFile?.publicURL
  const vis2d_attic =
    data?.acfApartment?.visualization2dAttic?.localFile?.publicURL
  const gallery = data?.acfApartment?.gallery

  const isDoubleFloorAvailable = vis2d_zero && vis2d_attic

  const settings = {
    className: "apartment-gallery",
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  return (
    <>
      <section className="apartment-main">
        <div className="container-fluid">
          <div className="apartment-main__back">
            <Button
              to="/mieszkania/"
              className="button--bordered button--narrow"
              state={{ view: viewState }}
            >
              <img src={IcoArrow} alt="" />
              powrót
            </Button>
          </div>

          <div className="row">
            <div className="col-lg-4">
              {/* {data?.acfApartment?.price && (
              <div className="apartment-main__list">
                <strong>Cena brutto</strong>
                <h2>
                  {data?.acfApartment?.regularPrice && (
                    <>
                      <h5>
                        {data?.acfApartment?.regularPrice.toLocaleString()}
                      </h5>
                      <br />
                    </>
                  )}
                  {data?.acfApartment?.price.toLocaleString()}
                  <span>PLN</span>
                </h2>
              </div>
            )}

            {data?.acfApartment?.priceForSquareMeter && (
              <div className="apartment-main__list">
                <strong>
                  Cena brutto /m<sup>2</sup>
                </strong>
                <h2>
                  {data?.acfApartment?.priceForSquareMeter.toLocaleString()}
                  <span>
                    PLN/m<sup>2</sup>
                  </span>
                </h2>
              </div>
            )} */}

              {data?.acfApartment?.area && (
                <div className="apartment-main__list">
                  <strong>Metraż</strong>
                  <h2>
                    {data?.acfApartment?.area}
                    <span>
                      m<sup>2</sup>
                    </span>
                  </h2>
                </div>
              )}

              {data?.acfApartment?.roomsnumber && (
                <div className="apartment-main__list">
                  <strong>Ilość pokoi</strong>
                  <h2>{data?.acfApartment?.roomsnumber}</h2>
                </div>
              )}

              {data?.acfApartment?.floor && (
                <div className="apartment-main__list">
                  <strong>Piętro</strong>
                  <h2>{data?.acfApartment?.floor}</h2>
                </div>
              )}

              {data?.acfApartment?.location && (
                <div className="apartment-main__list">
                  <strong>Ekspozycja</strong>
                  <h2>{data?.acfApartment?.location}</h2>
                </div>
              )}

              {data?.acfApartment?.roomsList && (
                <div className="apartment-list__rooms">
                  {data?.acfApartment?.roomsList?.map((item, index) => (
                    <div className="apartment-list__rooms-item" key={index}>
                      {index + 1}. {item.name}
                      <strong>
                        {item.area}m<sup>2</sup>
                      </strong>
                    </div>
                  ))}
                </div>
              )}

              <div className="apartment-list__cta">
                <a
                  className="button"
                  href={require(`assets/pdfs/${pdf_name}`).default}
                  download={pdf_name}
                >
                  pobierz pdf
                </a>
              </div>
              <div className="apartment-list__scroll">
                <button
                  className="button"
                  type="button"
                  onClick={() => scrollToElement("#contact")}
                >
                  zapytaj o mieszkanie
                </button>
              </div>
            </div>

            <div className="col-lg-7 offset-lg-1">
              <div className="apartment-main__nav">
                <div>
                  <strong>Widok</strong>
                  {(vis2d || isDoubleFloorAvailable) && (
                    <div className="apartment-main__nav-group">
                      <button
                        onClick={() => setView("2d")}
                        className={view === "2d" ? "current" : ""}
                      >
                        2D
                      </button>
                      {view === "2d" && isDoubleFloorAvailable && (
                        <div className="apartment-main__subnav">
                          <button
                            className={subview === "zero" ? "current" : ""}
                            onClick={() => setSubview("zero")}
                          >
                            Poziom 0
                          </button>
                          <button
                            className={subview === "attic" ? "current" : ""}
                            onClick={() => setSubview("attic")}
                          >
                            Poddasze
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {/* {data?.acfApartment?.visualization3d && (
                    <button
                      onClick={() => setView("3d")}
                      className={view === "3d" ? "current" : ""}
                    >
                      3D
                    </button>
                  )} */}
                  {/* {data?.acfApartment?.visualization360 && (
                  <button
                    onClick={() => setView("360")}
                    className={view === "360" ? "current" : ""}
                  >
                    360°
                  </button>
                )}
                {data?.acfApartment?.virtualWalk && (
                  <a
                    href={data?.acfApartment?.virtualWalk}
                    target="_blank"
                    rel="noreferrer"
                  >
                    wirtualny spacer
                  </a>
                )} */}
                </div>
                {view === "2d" && (
                  <img
                    src={require("assets/icons/ico-compass.svg").default}
                    alt=""
                  />
                )}
              </div>

              <div className="apartment-main__image">
                {view === "2d" && (
                  <button
                    type="button"
                    onClick={() =>
                      setLightbox(
                        isDoubleFloorAvailable
                          ? subview === "attic"
                            ? vis2d_attic
                            : vis2d_zero
                          : vis2d
                      )
                    }
                  >
                    <img
                      src={
                        isDoubleFloorAvailable
                          ? subview === "attic"
                            ? vis2d_attic
                            : vis2d_zero
                          : vis2d
                      }
                      alt=""
                    />
                  </button>
                )}
                {view === "3d" && (
                  <button
                    type="button"
                    onClick={() =>
                      setLightbox(data?.acfApartment?.visualization3d)
                    }
                  >
                    <img src={data?.acfApartment?.visualization3d} alt="" />
                  </button>
                )}
                {view === "360" && (
                  <iframe
                    title="Mazowiecka 115"
                    src={data?.acfApartment?.visualization360}
                    width="100%"
                    height="700px"
                  ></iframe>
                )}
              </div>

              <div className="apartment-list__floor">
                {data?.acfApartment?.floorView && (
                  <img
                    src={data?.acfApartment?.floorView.sourceUrl}
                    alt="{title}"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {gallery?.length > 0 && (
        <section>
          <Slider {...settings}>
            {gallery.map((item, index) => (
              <div key={index}>
                <div
                  className="apartment-gallery__item"
                  style={{
                    backgroundImage: `url(${item.localFile.publicURL})`,
                  }}
                />
              </div>
            ))}
          </Slider>
        </section>
      )}

      <AnimatePresence>
        {gallery?.length > 0 && isPopOpen && (
          <motion.div
            className="apartment-pop"
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: "tween", duration: 0.3 }}
          >
            <div className="apartment-pop__dialog">
              <div className="apartment-pop__content">
                <button
                  className="apartment-pop__close"
                  type="button"
                  onClick={() => setIsPopOpen(false)}
                >
                  Zamknij
                </button>
                <p>Wizualizacje</p>
                <Slider {...settings} ref={popSliderRef}>
                  {gallery.map((item, index) => (
                    <div key={index}>
                      <div
                        className="apartment-gallery__item"
                        style={{
                          backgroundImage: `url(${item.localFile.publicURL})`,
                        }}
                      />
                    </div>
                  ))}
                </Slider>
                <div className="apartment-pop__nav">
                  <button
                    className="apartment-pop__nav-arrow"
                    type="button"
                    onClick={() => popSliderRef.current.slickPrev()}
                  >
                    ←
                  </button>
                  <button
                    className="apartment-pop__nav-arrow"
                    type="button"
                    onClick={() => popSliderRef.current.slickNext()}
                  >
                    →
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {!!lightbox && (
        <Lightbox mainSrc={lightbox} onCloseRequest={() => setLightbox(null)} />
      )}
    </>
  )
}

export default Main
